import { NextResponse } from "next/server";
import { Chain, createPublicClient, http,PublicClient } from "viem";

import { SUPPORTED_CHAINS } from "@/constants/chains";

interface ApiErrorResponse<T extends {} = {}> {
  message: string;
  reason?: string;
  info?: T;
}

export const encodeQuery = <T extends {} = {}>(
  query: T,
  force?: boolean,
): string => {
  const q = new URLSearchParams();

  for (const [k, v] of Object.entries(query)) {
    if (v || force) {
      q.set(k, String(v));
    }
  }

  return q.toString();
};

export const errorResponse = <T extends {} = {}>(
  code: number,
  response: ApiErrorResponse<T>,
) => NextResponse.json(response, { status: code });

/** @deprecated change type Request -> NextRequest; NextRequest["nextUrl"] is what you need */
export const parseQuery = <T extends {} = {}>(
  request: Request,
  parser: (qs?: string, path?: string) => T,
): T => {
  const [path, query] = request.url.split("?");
  return parser(query, path);
};

type ChainName = keyof typeof SUPPORTED_CHAINS;

const isChainName = (chainName: string): chainName is ChainName => chainName in SUPPORTED_CHAINS;

export const multiChainClient = () => {
  const clients: Partial<Record<ChainName, PublicClient>> = {};

  return (chainName: string) => {
    if (!isChainName(chainName)) {
      throw new Error(`unsupported chain: ${chainName}`)
    }

    const chain: Chain = SUPPORTED_CHAINS[chainName];

    if (!clients[chainName]) {
      clients[chainName] = createPublicClient({ chain, transport: http() });
    }

    return clients[chainName]!;
  };
};
