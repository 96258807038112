import type { ChartPeriod } from "@/types/common";

export const W3_CACHE = {
  ETERNAL: {
    cacheTime: 300_000,
    staleTime: 120_000,
  },
  LONG: { cacheTime: 120_000, staleTime: 15_000 },
  NORMAL: {
    cacheTime: 60_000,
    staleTime: 5_000,
  },
  SHORT: {
    cacheTime: 15_000,
    staleTime: 0,
  },
};

export const MIN_LEVERAGE = 2;
export const PARAMS_DECIMALS = 6;

export const MAX_TO_WITHDRAW = (BigInt(2) ** BigInt(64)).toString(
  10,
) as `${number}`;

export const CHART_PERIOD: ChartPeriod[] = ["H", "4H", "D", "W", "M", "3M"];
export const NBSP = `\xA0`;

export const SWIPEABLE_CONFIG = {
  trackMouse: true, // track mouse input
  swipeDuration: 250,
};

export const SUGGESTIONS_PERCENTAGE = [1];
export const SUGGESTION_DECIMALS = 4;

/** @description seconds */
export const HOUR_IN_S = 60 * 60;
/** @description seconds */
export const DAY_IN_S = 24 * HOUR_IN_S;

/** @deprecated use theme vars */
export const CHART_BACK_GREEN = "#40BF00";
/** @deprecated use theme vars */
export const CHART_BACK_RED = "#E64595";
/** @deprecated use theme vars */
export const TEXT_GREEN = CHART_BACK_GREEN;
/** @deprecated use theme vars */
export const TEXT_RED = CHART_BACK_RED;

export const DATE_FORMAT = new Intl.DateTimeFormat("en-GB", {
  day: "2-digit",
  month: "short",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",

  hour12: true,
});

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const TRANSPARENT_PIXEL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export const NOTIFICATION_VISIBLE_MS = 5_000;

export const PORTAL_DOM_ID = "marginly-portal";
