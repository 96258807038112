"use client";
import { createContext, type ReactNode, useContext, useState } from "react";
import { BehaviorSubject, ReplaySubject } from "rxjs";

import { Notification } from "@/types/common";

import { globalUiDefault, useGlobalUi } from "./ui";

interface RxContextVals extends ReturnType<typeof useGlobalUi> {
  error$: ReplaySubject<Error>;
  notification$: ReplaySubject<Notification>;
  connect$: BehaviorSubject<boolean>;
}

const initialValues: RxContextVals = {
  ...globalUiDefault,
  connect$: new BehaviorSubject(false),
  error$: new ReplaySubject(1),
  notification$: new ReplaySubject(1),
};

const RxContext = createContext(initialValues);

export function RxProvider({ children }: { children?: ReactNode }) {
  const [connect$] = useState(() => new BehaviorSubject(false));

  const [error$] = useState(
    () => new ReplaySubject<Error>(1 /** num of errors to display */),
  );


  const globalUi = useGlobalUi();

  return (
    <RxContext.Provider
      value={{
        ...globalUi,
        error$,
        connect$,

        notification$: initialValues.notification$,
      }}
    >
      {children}
    </RxContext.Provider>
  );
}

export const useRx = () => useContext(RxContext);
