import { useEffect, useRef, useState } from "react";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export const useSubjectFrom = <T>(v: T) => {
  const initial = useRef(true);
  const [subject$] = useState(() => new BehaviorSubject(v));

  useEffect(() => {
    if (initial.current) {
      initial.current = false;
    } else {
      subject$.next(v);
    }
  }, [subject$, v]);

  return subject$.asObservable();
};

export const useStateFrom = <T>(o$: Observable<T>, initial?: T) => {
  // @ts-expect-error
  const [state, setState] = useState<T>(initial);

  useEffect(() => {
    const sub = o$.subscribe((v) => {
      setState(v);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [o$]);

  return state;
};

export const usePushSubject = <T>(subj?: Subject<T>, val?: T | null) => {
  useEffect(() => {
    if (val) {
      subj?.next(val);
    }
  }, [subj, val]);
};
