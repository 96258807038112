import { DAY_IN_S, HOUR_IN_S } from "@/constants/common";
import type { ChartPeriod } from "@/types/common";

import { MATCH_ALL } from "./constants";

export const chartPeriodToParams = (
  period: ChartPeriod | undefined | typeof MATCH_ALL,
) => {
  // fixme hack used for fallback extract
  if (!period || period === MATCH_ALL) {
    return { frame: MATCH_ALL, fromUnixS: MATCH_ALL };
  }

  let frame = "OneMinute";
  let offset = HOUR_IN_S;

  if (period === "4H") {
    frame = "FiveMinute";
    offset = 4 * HOUR_IN_S;
  } else if (period === "D") {
    offset = DAY_IN_S;
    frame = "FifteenMinute";
  } else if (period === "W") {
    offset = 7 * DAY_IN_S;
    frame = "OneHour";
  } else if (period === "M") {
    offset = 30 * DAY_IN_S;
    frame = "FourHour";
  } else if (period === "3M") {
    offset = 90 * DAY_IN_S;
    frame = "OneDay";
  }

  const fiveMinutes = 5 * 60 * 1000;
  const flooredToFiveMin = Math.floor(Date.now() / fiveMinutes) * fiveMinutes;
  let fromUnixS = flooredToFiveMin / 1000 - offset;
  return { frame, fromUnixS };
};
