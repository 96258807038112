"use client";

import { usePathname } from "next/navigation";
import { useCallback, useMemo, useState } from "react";
import { BehaviorSubject, combineLatestWith, map, of, scan } from "rxjs";

import { useSubjectFrom } from "@/hooks/rx";
import { BgType } from "@/types/common";
import { pathnameToBgType } from "@/util/common";

import { useDexPartner } from "../partner";

interface UiParams {
  localBgType?: BgType;
}

export const useGlobalUi = () => {
  const [uiParams$] = useState(() => new BehaviorSubject<UiParams>({}));

  const { partner } = useDexPartner();

  const setParams = useCallback(
    (params: UiParams) => uiParams$.next({ ...uiParams$.value, ...params }),
    [uiParams$],
  );

  const pathname$ = useSubjectFrom(usePathname());
  const { chainPath } = useDexPartner();

  return {
    navState$: useMemo(() => {
      return pathname$.pipe(
        scan<string, string[]>((acc, el) => {
          acc.push(el);
          return acc.slice(-2);
        }, []),
        combineLatestWith(uiParams$),
        map(([pathnames, uiParams]) => {
          const getBgType = () => {
            if (partner) return "home";
            return pathnameToBgType(current) || uiParams.localBgType;
          };
          const [current, previous] = [...pathnames].reverse();
          const back = previous ?? chainPath;

          return {
            back,
            bgType: getBgType(),
          };
        }),
      );
    }, [pathname$, uiParams$, chainPath, partner]),
    setParams,
  };
};

export const globalUiDefault: ReturnType<typeof useGlobalUi> = {
  navState$: of({
    back: "/",
    bgType: undefined,
  }),
  setParams: () => {},
};
