"use client";

import { createContext, type ReactNode, useContext } from "react";

import { getChainUrl } from "@/app/util";
import { Partner } from "@/util/server/env";

interface PartnerContext {
  partner?: Partner;
  chainPath: string;
}

const DexPartnerContext = createContext({} as PartnerContext);

const urlByPartner = {
  [Partner.Ramses]: "https://ramses.marginly.com",
  [Partner.Camelot]: "https://ramses.marginly.com",
  [Partner.TraderJoe]: "https://ramses.marginly.com",
};

export function PartnerProvider({
  children,
  lastNetwork,
  partner,
}: {
  children?: ReactNode;
  lastNetwork?: string;
  partner?: Partner;
}) {
  const url =
    typeof window !== "undefined"
      ? window.location.href
      : partner
      ? urlByPartner[partner]
      : "https://app.marginly.com";

  const chainUrl = getChainUrl(url, lastNetwork, partner);
  return (
    <DexPartnerContext.Provider
      value={{ partner, chainPath: chainUrl.pathname }}
    >
      {children}
    </DexPartnerContext.Provider>
  );
}

export const useDexPartner = () => useContext(DexPartnerContext);
