import { getFetcher } from "@marginly/ui/features/terms-and-conditions/hooks";
import { createContext, useContext, useMemo } from "react";
import useSWR from "swr";

import { useRx } from "@/contexts/rx";
import {
  createEndpointConfig,
  EndpointArgs,
  Endpoints,
  EndpointValues,
  validateResponse,
} from "@/util/api/api";

import { useCandleApi } from "./candles";

type PartialArgs<T> = T extends [infer U, ...infer V]
  ? [undefined | U, ...PartialArgs<V>]
  : [];

const notPartial = (v: unknown[]) => {
  return !v.some((x) => typeof x === "undefined");
};

export const useBaseUrl = () =>
  useContext(MarginlyApiContext).baseurl ?? "http://localhost:4000";

export const MarginlyApiContext = createContext<
  {
    baseurl?: string;
    serverTime: number;
  } & Partial<ReturnType<typeof useCandleApi>>
>({
  serverTime: -1,
});

export const useMarginlyApi = <T extends Endpoints, V = EndpointValues[T]>({
  enabled = true,
  endpoint,
  args,
  allowEmptyResponse,
  transform,
  allowPartial,
}: {
  enabled?: boolean;
  endpoint: T;
  args: PartialArgs<EndpointArgs[T]>;
  allowEmptyResponse?: boolean;
  transform?: (raw: EndpointValues[T]) => V;
  /** @deprecated fixme rework api config */
  allowPartial?: boolean;
}) => {
  const baseurl = useBaseUrl();

  const { error$ } = useRx();

  const { url, method, body } = useMemo(() => {
    if (enabled && (notPartial(args) || allowPartial)) {
      const _args = args as unknown as EndpointArgs[T];

      const { url, method, body } = createEndpointConfig(
        { args: _args, name: endpoint },
        baseurl,
      );

      return { url, method, body };
    }

    return {};
  }, [enabled, endpoint, args, baseurl, allowPartial]);

  const fetcher = useMemo(() => {
    return getFetcher(
      (raw: any): raw is EndpointValues[T] => validateResponse(endpoint, raw),
      {
        onError: (err) => {
          if (err) {
            error$.next(err);
          }
        },
        throwOnError: false,
        transform,
        allowEmptyResponse,
        method,
        body,
      },
    );
  }, [allowEmptyResponse, body, endpoint, error$, method, transform]);

  const result = useSWR(url, fetcher);
  return useMemo(() => ({ ...result, baseurl }), [baseurl, result]);
};
