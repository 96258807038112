export enum Partner {
  "Ramses" = "Ramses",
  "TraderJoe" = "TraderJoe",
  "Camelot" = "Camelot",
}

export default function getEnv() {
  const partner = process.env.PARTNER_DEX as Partner | undefined;
  return { partner };
}
