"use client";
import { createContext } from "react";

import type { ContextValues } from "./types";

export const Web3Context = createContext<ContextValues>({
  pools: [],
  ready: false,
  pending: true,
  tokens: [],
  tokenData: {},
  getPoolByAddress: () => {
    throw new Error("not implemented");
  },
  open: () => {
    throw new Error("not implemented");
  },
});
