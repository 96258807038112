import type { TokenAggregateResponse } from "@/app/api/balance/types";
import type { SwapAllowanceResponse } from "@/app/api/swap/allowance/route";
import type { SwapBuildResponse } from "@/app/api/swap/build/route";
import type { SwapEstimateResponse } from "@/app/api/swap/estimate/route";
import { TokenSearchResponse } from "@/app/api/tokens/route";
import { encodeQuery } from "@/app/api/util";
import type { SwapRouter } from "@/constants/enum";
import type { MemberInfo } from "@/external/types/member";
import type {
  ApiLendPositionEarnedResponse,
  ApiPoolAprResponse,
  ApiPoolPnlHistoryByPoolResponse,
  ApiPoolPnlHistoryResponse,
  ApiPoolPnlResponse,
  ApiPositionDetailsResponse,
  ApiResult,
  ApiSwapCandlesResponse,
  ApiTotalsResponse,
  ApiTvlResponse,
  BonusResponse,
  ByTeamResponseType,
  CallSwapType,
  ChainSearchResponse,
  ContestMemberExist,
  EarnResponse,
  LeaderboardPointsResponse,
  LeaderboardScore,
  LeaderboardTotalMemberNumber,
  Member,
  PoolImpliedApyResponse,
  PositionSwapType,
  SwapCallDataResponse,
  UserInfoResponse,
  UserPointsResponse,
  UserSignatureResponse,
} from "@/types/api";
import { LeaderboardPnlResponse } from "@/types/api";
import type { ChartPeriod } from "@/types/common";

import { chartPeriodToParams } from "./chart-period-to-params";
import { MATCH_ALL } from "./constants";
import { validators } from "./validators";

export interface EndpointArgs {
  chainSearch: [number];
  positionDetails: [number, `0x${string}`, `0x${string}`];
  positionDetailsMulti: [number, `0x${string}`, `0x${string}`[]];
  lendPositionEarned: [number, `0x${string}`, `0x${string}`];
  poolApr: [number, `0x${string}`, number] | [number, `0x${string}`];
  poolPnl: [number, `0x${string}`, `0x${string}`];
  pnlHistory: [number, `0x${string}`, number | undefined, string | undefined];
  pnlHistoryByPool: [
    number,
    `0x${string}`,
    `0x${string}`,
    number | undefined,
    string | undefined,
  ];
  swapCandles: [number, `0x${string}`, ChartPeriod | undefined];
  totals: [number, `0x${string}`];
  "leaderboard.byTeams": [number];
  "leaderboard.memberInfo": [number, string];
  "leaderboard.placement": [string, number];
  "leaderboard.score": [number, string];
  "leaderboard.pnl": [number, number];
  "leaderboard.totalMemberNumber": [number];
  "router.swapCallData": [
    number,
    `0x${string}`,
    CallSwapType,
    string,
    PositionSwapType,
    string,
  ];
  "local.swapAllowance": [
    string,
    SwapRouter,
    `0x${string}`,
    `0x${string}` | undefined,
    bigint,
  ];
  "local.estimateSwap": [
    string,
    SwapRouter,
    `0x${string}` | undefined,
    `0x${string}` | undefined,
    bigint,
  ];
  "local.buildSwapRoute": [
    string,
    SwapRouter,
    `0x${string}`,
    `0x${string}` | undefined,
    `0x${string}` | undefined,
    bigint,
  ];
  "local.balanceAggregate": [string, SwapRouter, `0x${string}`];
  "local.tokens": [string, SwapRouter, string];
  "contest.memberExist": [number, string];
  poolTvl: [number, `0x${string}`];
  earnInfo: [number];
  poolImpliedApy: [number, `0x${string}`];
  userInfo: [`0x${string}`];
  userPoints: [`0x${string}`];
  bonusInfo: [string];
  leaderboardPoints: [number];
  userSignature: [string];
}

export interface EndpointValues {
  chainSearch: ChainSearchResponse;
  positionDetails: ApiPositionDetailsResponse;
  positionDetailsMulti: ApiPositionDetailsResponse[];
  lendPositionEarned: ApiLendPositionEarnedResponse;
  poolApr: ApiPoolAprResponse;
  poolPnl: ApiPoolPnlResponse;
  pnlHistory: ApiPoolPnlHistoryResponse;
  pnlHistoryByPool: ApiPoolPnlHistoryByPoolResponse;
  swapCandles: ApiSwapCandlesResponse;
  totals: ApiTotalsResponse;
  "leaderboard.byTeams": ByTeamResponseType;
  "leaderboard.memberInfo": MemberInfo;
  "leaderboard.placement": Member;
  "leaderboard.score": LeaderboardScore[];
  "leaderboard.pnl": LeaderboardPnlResponse;
  "leaderboard.totalMemberNumber": LeaderboardTotalMemberNumber;
  "router.swapCallData": SwapCallDataResponse;
  "local.swapAllowance": SwapAllowanceResponse;
  "local.estimateSwap": SwapEstimateResponse;
  "local.buildSwapRoute": SwapBuildResponse;
  "local.balanceAggregate": TokenAggregateResponse;
  "local.tokens": TokenSearchResponse;
  "contest.memberExist": ContestMemberExist;
  userInfo: UserInfoResponse;
  poolTvl: ApiTvlResponse;
  earnInfo: EarnResponse;
  poolImpliedApy: PoolImpliedApyResponse;
  userPoints: UserPointsResponse;
  bonusInfo: BonusResponse;
  leaderboardPoints: LeaderboardPointsResponse;
  userSignature: UserSignatureResponse;
}

export type Endpoints = keyof EndpointArgs;

export const createEndpointConfig = <T extends Endpoints>(
  {
    args,
    name,
  }: {
    args: EndpointArgs[T];
    name: T;
  },
  baseurl: string,
): { url: string; body?: string; method?: RequestInit["method"] } => {
  switch (name) {
    case "chainSearch": {
      const [chainId] = args;
      return { url: `${baseurl}/chain/search?publicChainId=${chainId}` };
    }
    case "poolApr": {
      const [internalChainId, pool, leverage] = args as EndpointArgs["poolApr"];

      return {
        url: `${baseurl}/chain/${internalChainId}/marginlypool/${pool}/apr${
          leverage ? "?leverage=" + leverage : ""
        }`,
      };
    }

    case "swapCandles": {
      const [internalChainId, pool /* should be uniswap pool */, period] =
        args as EndpointArgs["swapCandles"];

      const params = chartPeriodToParams(period);

      return {
        url: `${baseurl}/chain/${internalChainId}/pool/${pool}/swapCandles?frame=${params.frame}&fromUnixS=${params.fromUnixS}`,
      };
    }

    case "poolPnl": {
      const [internalChainId, pool, user] = args as EndpointArgs["poolPnl"];
      return {
        url: `${baseurl}/chain/${internalChainId}/marginlypool/${pool}/user/${user}/pnl`,
      };
    }

    case "pnlHistory": {
      const [internalChainId, user, limit, from] =
        args as EndpointArgs["pnlHistory"];
      return {
        url: `${baseurl}/chain/${internalChainId}/user/${user}/pnl/history?limit=${limit}&includeOpenPositions=false${
          from ? `&from=${from}` : ""
        }`,
      };
    }

    case "pnlHistoryByPool": {
      const [internalChainId, pool, user, limit] =
        args as EndpointArgs["pnlHistoryByPool"];
      return {
        url: `${baseurl}/chain/${internalChainId}/marginlyPool/${pool}/user/${user}/pnl/history?limit=${limit}`,
      };
    }

    case "positionDetails": {
      const [internalChainId, pool, user] = args as EndpointArgs["poolPnl"];
      return {
        url: `${baseurl}/chain/${internalChainId}/marginlypool/${pool}/user/${user}`,
      };
    }

    case "positionDetailsMulti": {
      const [internalChainId, user, pools] =
        args as EndpointArgs["positionDetailsMulti"];

      return {
        url: `${baseurl}/chain/${internalChainId}/marginlypool/user/${user}/positionDetails?marginlyPools=${pools.join(
          ",",
        )}`,
      };
    }

    case "lendPositionEarned": {
      const [internalChainId, pool, user] = args as EndpointArgs["poolPnl"];
      return {
        url: `${baseurl}/chain/${internalChainId}/marginlyPool/${pool}/user/${user}/lendPositionEarned`,
      };
    }

    case "totals": {
      const [internalChainId, pool] = args as EndpointArgs["totals"];
      return {
        url: `${baseurl}/chain/${internalChainId}/marginlypool/${pool}`,
      };
    }

    case "leaderboard.byTeams": {
      const [internalChainId] = args as EndpointArgs["leaderboard.byTeams"];
      return { url: `${baseurl}/leaderboard/chain/${internalChainId}/byTeam` };
    }

    case "leaderboard.memberInfo": {
      const [contestId, address] =
        args as EndpointArgs["leaderboard.memberInfo"];
      return {
        url: `${baseurl}/contest/${contestId}/member/${address}`,
      };
    }

    case "leaderboard.placement": {
      const [address, contestId] =
        args as EndpointArgs["leaderboard.placement"];
      return {
        url: `${baseurl}/leaderboard/memberPlacement/${address}?contestId=${contestId}`,
      };
    }

    case "local.swapAllowance": {
      const [chain, swapRouter, address, tokenIn, amountIn] =
        args as EndpointArgs["local.swapAllowance"];

      const url = `/api/swap/allowance?${encodeQuery({
        address,
        chain,
        swapRouter,
        tokenIn,
        amountIn,
      })}`;

      return { url };
    }

    case "local.estimateSwap": {
      const [chain, swapRouter, tokenIn, tokenOut, amountIn] =
        args as EndpointArgs["local.estimateSwap"];

      const url = `/api/swap/estimate?${encodeQuery({
        chain,
        swapRouter,
        tokenIn,
        tokenOut,
        amountIn,
      })}`;

      return { url };
    }
    case "local.buildSwapRoute": {
      const [chain, swapRouter, address, tokenIn, tokenOut, amountIn] =
        args as EndpointArgs["local.buildSwapRoute"];

      const url = `/api/swap/build?${encodeQuery({
        chain,
        swapRouter,
        address,
        tokenIn,
        tokenOut,
        amountIn,
      })}`;
      return { url };
    }

    case "local.balanceAggregate": {
      const [chain, swapRouter, address] =
        args as EndpointArgs["local.balanceAggregate"];
      const url = `/api/balance?address=${address}&chain=${chain}&swapRouter=${swapRouter}`;
      return { url };
    }

    case "leaderboard.score": {
      const [contestNumber, address] =
        args as EndpointArgs["leaderboard.score"];
      const url = `${baseurl}/contest/${contestNumber}/member/${address}/score`;
      return { url };
    }

    case "leaderboard.pnl": {
      const [contestNumber, limit] = args as EndpointArgs["leaderboard.pnl"];
      const url = `${baseurl}/contest/${contestNumber}/leaderboard?top=${limit}`;
      return { url };
    }

    case "leaderboard.totalMemberNumber": {
      const [contestNumber] =
        args as EndpointArgs["leaderboard.totalMemberNumber"];
      const url = `${baseurl}/contest/${contestNumber}/totalMemberNumber`;
      return { url };
    }

    case "router.swapCallData": {
      const [
        internalChainId,
        poolAddress,
        callType,
        exactAmount,
        // only for callType === CallSwapType.ClosePosition
        positionType = "Undefined",
        dexName,
      ] = args as EndpointArgs["router.swapCallData"];
      const url = `${baseurl}/chain/${internalChainId}/router/swapCalldata/${poolAddress}/${callType}?exactAmount=${exactAmount}&positionType=${positionType}&swapPriority=${dexName}`;
      return { url };
    }

    case "contest.memberExist": {
      const [contestNumber, memberAddress] =
        args as EndpointArgs["contest.memberExist"];
      const url = `${baseurl}/contest/${contestNumber}/member/${memberAddress}/exists`;
      return { url };
    }

    case "local.tokens": {
      const [search, swapRouter, chain] =
        args as EndpointArgs["local.swapAllowance"];

      const url = `/api/tokens?${encodeQuery({
        search,
        swapRouter,
        chain,
      })}`;

      return { url };
    }

    case "poolTvl": {
      const [internalChainId, pool] = args as EndpointArgs["poolTvl"];
      return {
        url: `${baseurl}/chain/${internalChainId}/tvl?marginlyPool=${pool}`,
      };
    }

    case "earnInfo": {
      const [chainId] = args as EndpointArgs["earnInfo"];
      return {
        url: `${baseurl}/chain/${chainId}/earnInfo`,
      };
    }

    case "userInfo": {
      const [address] = args as EndpointArgs["userInfo"];
      return {
        url: `${baseurl}/users/${address}`,
      };
    }

    case "poolImpliedApy": {
      const [chainId, poolAddress] = args as EndpointArgs["poolImpliedApy"];
      return {
        url: `${baseurl}/chain/${chainId}/pool/${poolAddress}/impliedApy`,
      };
    }

    case "userPoints": {
      const [address] = args as EndpointArgs["userPoints"];
      return {
        url: `${baseurl}/points/${address}`,
      };
    }

    case "bonusInfo": {
      const [code] = args as EndpointArgs["bonusInfo"];
      return {
        url: `${baseurl}/points/bonus/${code}`,
      };
    }

    case "leaderboardPoints": {
      const [top] = args as EndpointArgs["leaderboardPoints"];
      return {
        url: `${baseurl}/points?top=${top}`,
      };
    }

    case "userSignature": {
      const [userAddress] = args as EndpointArgs["userSignature"];
      return {
        url: `${baseurl}/users/${userAddress}/signature`,
      };
    }

    default:
      ((exhaustiveCheck: never) => {})(name);
      throw new Error(`unknown endpoint: ${name}`);
  }
};

export const validateResponse = <T extends Endpoints>(
  name: T,
  value: any,
): value is EndpointValues[T] => {
  const validator = validators[name];

  if (typeof validator !== "function") {
    throw new Error(`unknown validator: ${name}`);
  }

  return validators[name](value);
};

export const isSuccess = <T extends {}>(
  raw?: ApiResult<any, T>,
): raw is ApiResult<true, T> => raw?.success;

export const fallbackExtract = <T extends Endpoints>(
  fallback: Record<string, any>,
  name: T,
) => {
  const { url: _template } = createEndpointConfig(
    { name, args: Array.from({ length: 6 }).map(() => MATCH_ALL) as any },
    "",
  );

  const template = _template.replace(/\//g, "\\/").replace(/\?/g, "\\?");
  const re = new RegExp(template, "i");
  const keys = Object.keys(fallback);
  const result: [string, EndpointValues[T], RegExpExecArray][] = [];

  for (const key of keys) {
    const match = re.exec(key);

    if (match) {
      result.push([key, fallback[key], match]);
    }
  }

  return result;
};
