import type { TokenAggregateResponse } from "@/app/api/balance/types";
import type { SwapAllowanceResponse } from "@/app/api/swap/allowance/route";
import type { SwapEstimateResponse } from "@/app/api/swap/estimate/route";
import { TokenSearchResponse } from "@/app/api/tokens/route";
import { BuildSwapQuery } from "@/app/api/types";
import type { MemberInfo } from "@/external/types/member";
import type {
  ApiLendPositionEarnedResponse,
  ApiPoolAprResponse,
  ApiPoolPnlHistoryByPoolResponse,
  ApiPoolPnlHistoryResponse,
  ApiPoolPnlResponse,
  ApiPositionDetailsResponse,
  ApiSwapCandlesResponse,
  ApiTotalsResponse,
  ApiTvlResponse,
  BonusResponse,
  ByTeamResponseType,
  ChainSearchResponse,
  ContestMemberExist,
  EarnResponse,
  LeaderboardPnlResponse,
  LeaderboardPointsResponse,
  LeaderboardScore,
  LeaderboardTotalMemberNumber,
  MemberPlacementError,
  PoolImpliedApyResponse,
  SwapCallDataResponse,
  UserPointsResponse,
  UserSignatureResponse,
} from "@/types/api";

const isPoolAprResponse = (raw: any): raw is ApiPoolAprResponse => {
  if (!raw) {
    return false;
  }

  return ["lendCurrentBaseApr", "lendCurrentQuoteApr"].every((k) => k in raw);
};
const isSwapCandlesResponse = (raw: any): raw is ApiSwapCandlesResponse => {
  if (!raw || !Array.isArray(raw.points)) {
    return false;
  }

  if (!raw.points.length) {
    return true;
  }

  // assume that all points have same structure
  if (!Array.isArray(raw.points[0])) {
    return false;
  }

  const [timestamp, point] = raw.points[0];

  if (typeof timestamp !== "number" || typeof point !== "object") {
    return false;
  }

  return [
    "openTimestamp",
    "frame",
    "baseCurrency",
    "quoteCurrency",
    "open",
    "high",
    "low",
    "close",
    "volumeBuy",
    "volumeSell",
  ].every((k) => k in point);
};
const isPoolPnlResponse = (raw: any): raw is ApiPoolPnlResponse => {
  if (!raw) return false;

  return ["pnl", "positionType", "currentBasePrice", "pnlPoints"].every(
    (k) => k in raw,
  );
};
const isPoolPnlHistoryResponse = (
  raw: any,
): raw is ApiPoolPnlHistoryResponse => {
  if (!raw) return false;

  return ["poolsHistory"].every((k) => k in raw);
};
const isPoolPnlHistoryByPoolResponse = (
  raw: any,
): raw is ApiPoolPnlHistoryByPoolResponse => {
  if (!raw) return false;

  return ["userPnlPoints"].every((k) => k in raw);
};
const isPositionDetailsResponse = (
  raw: any,
): raw is ApiPositionDetailsResponse => {
  if (!raw) return false;

  return ["liquidationPrice", "totalCollateral", "totalDebt"].every(
    (k) => k in raw,
  );
};
// todo validate items
const isPositionDetailsMultiResponse = (
  raw: any,
): raw is ApiPositionDetailsResponse[] => Array.isArray(raw);
const isLendPositionEarnedResponse = (
  raw: any,
): raw is ApiLendPositionEarnedResponse => {
  if (!raw) return false;

  return ["base", "quote"].every((k) => k in raw && typeof raw[k] === "number");
};
const isChainSearchResponse = (raw: any): raw is ChainSearchResponse =>
  !!raw?.chainInfo;
const isTotalsResponse = (raw: any): raw is ApiTotalsResponse => {
  if (!raw) return false;

  return ["baseCollateral", "baseDebt", "quoteCollateral", "quoteDebt"].every(
    (k) => k in raw,
  );
};
const validatePoolImpliedApyResponse = (
  raw: any,
): raw is PoolImpliedApyResponse => {
  if (!raw) return false;

  return ["impliedApy"].every((k) => k in raw);
};
const validateUserInfoResponse = (raw: any): raw is PoolImpliedApyResponse => {
  if (!raw) return false;

  return ["id", "referralCode"].every((k) => k in raw);
};
const validateTeamResponse = (raw: any): raw is ByTeamResponseType => {
  if (!Array.isArray(raw)) {
    return false;
  }
  if (raw.length == 0) {
    return true;
  }
  const item = raw[0];

  return ["team", "balanceValue", "positionsValue"].every((key) =>
    Reflect.has(item, key),
  );
};
// fixme member info
const validateMemberInfo = (raw: any): raw is MemberInfo => Boolean(raw);
const validateMemberPlacement = (
  raw: any,
): raw is number | MemberPlacementError =>
  typeof raw === "number" || typeof raw === "object";
const validateLeaderboardScore = (raw: any): raw is LeaderboardScore[] =>
  raw?.every?.(
    (item: any) =>
      typeof item === "object" &&
      typeof item.type === "string" &&
      typeof item.score === "number" &&
      typeof item.place === "number",
  );

const validatePnlLeaderboard = (raw: any): raw is LeaderboardPnlResponse =>
  Array.isArray(raw) &&
  raw.every(
    (line: any) =>
      typeof line.userAddress === "string" || typeof line.score === "number",
  );
const validateTotalMemberNumber = (
  raw: any,
): raw is LeaderboardTotalMemberNumber => typeof raw === "number";
const validateSwapCallData = (raw: any): raw is SwapCallDataResponse =>
  typeof raw === "object" && "swapCalldata" in raw;
const validateSwapAllowanceResponse = (
  raw: any,
): raw is SwapAllowanceResponse => {
  return typeof raw === "object";
};
const validateEstimateSwapResponse = (
  raw: any,
): raw is SwapEstimateResponse => {
  return typeof raw === "object";
};
const validateBuildSwapRouteResponse = (raw: any): raw is BuildSwapQuery => {
  return typeof raw === "object";
};
const validateBalanceAggregateResponse = (
  raw: any,
): raw is TokenAggregateResponse => typeof raw === "object";
const validateTokensResponse = (raw: any): raw is TokenSearchResponse => {
  return typeof raw === "object";
};
const validateMemberExistResponse = (raw: any): raw is ContestMemberExist => {
  return typeof raw === "boolean";
};
const validateTvlResponse = (raw: any): raw is ApiTvlResponse =>
  raw && Reflect.has(raw, "tvlUsd");
const validateEarnInfoResponse = (raw: any): raw is EarnResponse =>
  typeof raw === "object";
const validateUserPointsResponse = (raw: any): raw is UserPointsResponse => {
  if (!raw) return false;

  return ["userAddress", "balance", "updatedAt"].every((k) => k in raw);
};
const validateBonusInfoResponse = (raw: any): raw is BonusResponse => {
  if (!raw) return false;

  return ["userAddress", "email"].every((k) => k in raw);
};
const validateLeaderboardPointsResponse = (
  raw: any,
): raw is LeaderboardPointsResponse => {
  if (!raw) return false;

  return ["top", "total"].every((k) => k in raw);
};
const userSignatureValidate = (raw: any): raw is UserSignatureResponse =>
  Array.isArray(raw);

export const validators = {
  chainSearch: isChainSearchResponse,
  poolApr: isPoolAprResponse,
  swapCandles: isSwapCandlesResponse,
  poolPnl: isPoolPnlResponse,
  pnlHistory: isPoolPnlHistoryResponse,
  pnlHistoryByPool: isPoolPnlHistoryByPoolResponse,
  positionDetails: isPositionDetailsResponse,
  positionDetailsMulti: isPositionDetailsMultiResponse,
  lendPositionEarned: isLendPositionEarnedResponse,
  totals: isTotalsResponse,
  "leaderboard.byTeams": validateTeamResponse,
  "leaderboard.memberInfo": validateMemberInfo,
  "leaderboard.placement": validateMemberPlacement,
  "leaderboard.teamPlacement": validateMemberPlacement,
  "leaderboard.score": validateLeaderboardScore,
  "leaderboard.pnl": validatePnlLeaderboard,
  "leaderboard.totalMemberNumber": validateTotalMemberNumber,
  "router.swapCallData": validateSwapCallData,
  "local.swapAllowance": validateSwapAllowanceResponse,
  "local.estimateSwap": validateEstimateSwapResponse,
  "local.buildSwapRoute": validateBuildSwapRouteResponse,
  "local.balanceAggregate": validateBalanceAggregateResponse,
  "local.tokens": validateTokensResponse,
  "contest.memberExist": validateMemberExistResponse,
  poolTvl: validateTvlResponse,
  earnInfo: validateEarnInfoResponse,
  poolImpliedApy: validatePoolImpliedApyResponse,
  userInfo: validateUserInfoResponse,
  userPoints: validateUserPointsResponse,
  bonusInfo: validateBonusInfoResponse,
  leaderboardPoints: validateLeaderboardPointsResponse,
  userSignature: userSignatureValidate,
} as const;
